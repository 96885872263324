.headerContainer {
  margin: 40px 80px 20px 80px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.headerFlexWrap {
  display: flex;
  flex-wrap: wrap;
}

.companyHeaderFlex {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}

.emailContainer {
  font-size: 16px;
  margin-top: 5px;
}

.socialMediaBlock {
  margin-top: 5px;

  &.hasEmails {
    margin-top: 16px;
  }
}

.companyLogo {
  margin-left: auto;
  max-height: 50px;
  max-width: 50px;
  object-fit: contain;
  border-radius: 100%;
}

.dataPointsContainer {
  display: flex;
  gap: 24px;
  margin-left: -23px;
  max-height: fit-content;
  flex-wrap: wrap;
}

.dataPoint {
  width: 150px;
  flex-shrink: 0;
}