@import "~@blueprintjs/core/src/common/variables";
@import "../../../../../palette.module";

.tabs {
  display: flex;
  margin: 0 0 0 50px;
  background-color: $background-blue;
  border-radius: 8px 8px 0 0;
  padding: 8px;
  list-style: none;
  max-width: fit-content;
  gap: 10px;
  min-height: 65px;

  & > li {
    & > * {
      display: block;
      border-radius: 8px 8px 0 0;
      padding: 8px;
      background-color: white;
      color: #333;
      font-size: 13px;
      width: 148px;
      text-align: center;
      height: 45px;
      @media screen and (max-width: 920px) {
        line-height: 1;
      }
      @media screen and (max-width: 1267px) {
        width: 100%;
      }
    }
    
    

    & > a {
      text-decoration: none;

      &:hover:not(.disabled):not(.current) {
        color: white;
        background-color: $blue5;
        // font-weight: bold;
      }

      &:active, &.current {
        color: white !important;
        background-color: $blue3 !important;
        // font-weight: bold;
      }
    }

    & > span.disabled {
      color: #9BABB6;
      background-color: $background-blue-disabled;
      cursor: not-allowed;
    }
  }
}