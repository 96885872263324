.linkedinCard {
  min-height: 14em;
  overflow: auto;
}

.linkedinHeaderContainer {
  display: flex;
  gap: 15px;
  align-items: center;
}

.linkedinHeaderLogo {
  border-radius: 50%;
}

.linkedinInfoContainer {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.linkedinName {
  font-weight: 700;
}

.linkedinDivider {
  width: 100%;
  height: 1px;
  border: none;
  background-color: #dee3ec;
}

.employeeCount {
  margin-left: 8px;
  font-weight: 800;
}

.linkedinEmployeeGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

.viewMoreEmployees {
  text-align: center;
  width: 100%;
  display: inline-block;
}