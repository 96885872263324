.fundingContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.financialsGrid {
  display: grid;
  column-gap: 16px;
  grid-template-columns: repeat(4, 25%);
}

.fundingGrid {
  display: grid;
  column-gap: 16px;
  grid-template-columns: repeat(4, 25%);
}

.investorsList {
  display: grid;
  column-gap: 16px;
  grid-template-columns: repeat(4, 25%);
  list-style: inside;
  padding: 0;
  margin: 0;
}