.growthContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.growthDataPointsGrid {
  display: grid;
  column-gap: 16px;
  grid-template-columns: repeat(3, 33%);
}

.growthChartGrid {
  display: grid;
  column-gap: 16px;
  grid-template-columns: repeat(3, 33%);
}