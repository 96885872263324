@import "~@blueprintjs/core/src/common/variables";
@import "../../../palette.module";

.detailsPagecontainer {
  display: flex;
  height: 100%;
}

.detailsPageMainContent {
  width: calc(100% - var(--right-sidebar-width));
}

.detailsPageContentWrapper {
  height: calc(100% - 50px);
  overflow: auto;
  display: flex;
  flex-direction: column;
}
